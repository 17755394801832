import React from "react";
import Editor from "@monaco-editor/react";

const CodeInput = (props) => {
    function handleChangeContext(value, event) {
        props.updateValue(props.keymap, value);
    }
    return (
        props.hide ? <></> :
            <div className={"column"} >
                <div className="title-code">
                    {props.title}
                </div>
                <Editor
                    defaultLanguage={props.language}
                    language={props.language}
                    value={props.data}
                    onChange={handleChangeContext}
                    theme="vs-dark"
                />
            </div>);
}

export default CodeInput;