export const context = `{
	"data": [
		{
			"name": "sample 1",
			"value": "value 1"
		},
		{
			"name": "sample 2",
			"value": "value 2"
		},
		{
			"name": "sample 3",
			"value": "value 3"
		}
	]
}`;

export const template = `{% for items in data %}{% for key, val in items %}{{ val }}	{% endfor %}
{% endfor %}`;
