export const context = `{
    "functionName": "firstFunction",
    "data": [
        {
            "name": "sample1",
            "value": "value1"
        },
        {
            "name": "sample2",
            "value": "value2"
        }
    ]
}`;

export const template = `function: {{ functionName }}
{% for item in data %}{{ item.value }}
{% endfor %}`;
