import React, { useEffect, useRef, useState } from "react";
import nunjucks from "nunjucks";
import { VscJson, VscSymbolArray, VscBracketDot, VscListOrdered, VscNote, VscSave, VscExport, VscDatabase, VscHeartFilled, VscChecklist } from "react-icons/vsc";
// https://react-icons.github.io/react-icons
import "./App.scss";
import CodeInput from "./component/CodeInput";
import { saveAs } from 'file-saver';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-dropdown/style.css';
import Dropdown from 'react-dropdown';
import { contextData, templateData } from "./data";

var JSZip = require("jszip");
function App() {
  const [context, setContext] = useState("");
  const [rawData, setRawData] = useState("");
  const [template, setTemplate] = useState("");
  const [output, setOutput] = useState("");
  const [language, setLanguage] = useState("plaintext");
  const [lsJson, setLsJson] = useState([]);
  const [currentIdx, setCurrentIdx] = useState(-1);

  const [isUnsaved, setIsUnsaved] = useState(false);
  const [hide, setHide] = useState([false, false, false]);

  //ref for fullscreen
  const GENERATE_TYPE_OBJECT = "object";
  const GENERATE_TYPE_ARRAY = "array";
  const GENERATE_TYPE_DATA = "data";

  const notify = (msg) => {
    toast.info(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };
  const error = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  };

  function useKey(key, cb) {
    const callback = useRef(cb);

    useEffect(() => {
      callback.current = cb;
    })

    useEffect(() => {
      function handle(event) {
        if (event.code === key) {
          callback.current(event);
        } else if (key === 'ctrls' && event.key === 's' && event.ctrlKey) {
          event.preventDefault();
          callback.current(event);
        }
      }

      document.addEventListener('keydown', handle);
      return () => document.removeEventListener("keydown", handle)
    }, [key])
  }

  function handleSetIndex(event) {
    selectItem(event.target.id);
  }
  function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  function render() {
    if (isJsonString(context)) {
      if (!Array.isArray(JSON.parse(context))) {
        setLsJson([]);
        setOutput("");
        error("Chỗ multiple này thì cần phải là array của object nhé.");
        return;
      }
      setLsJson(JSON.parse(context));
      setOutput("<-- click vào danh sách bên này để ra thông tin nhé");
      setCurrentIdx(-1);
      notify("Thành công rồi, click chọn tên dưới kia để xem thông tin.");
    } else {
      error("Json kiểu gì sai tùm lum sao mà generate được má.");
    }
  }

  function renderSingle() {

    if (isJsonString(context)) {
      if (Array.isArray(JSON.parse(context))) {
        setLsJson([]);
        setOutput("");
        error("Chỗ single này thì cần phải là object nhé.");
        return;
      }
      setLsJson([]);
      const rendered = nunjucks.renderString(template, JSON.parse(context));
      setOutput(rendered);
      console.log(rendered);
      notify("Thành công rồi, xem kết quả bên dưới nhé.");
    } else {
      error("Json kiểu gì sai tùm lum sao mà generate được má.");
    }
    setCurrentIdx(-1);

  }


  function getTemplate(name) {
    setContext(contextData(name));
    setTemplate(templateData(name));
  }

  function selectItem(idx) {
    nunjucks.configure({ autoescape: true });
    const rendered = nunjucks.renderString(template, lsJson[idx]);
    setOutput(rendered);
    setCurrentIdx(idx);
  }
  function exportResult() {
    var zip = new JSZip();
    nunjucks.configure({ autoescape: true });
    lsJson.forEach((item, id) => {
      let content = nunjucks.renderString(template, lsJson[id]);
      //zip.file(lsJson[id].name + "_" + id + ".hau", content);
      zip.file(lsJson[id].name + ".json", content);
    });
    zip.generateAsync({ type: "blob" }).then(function (content) {
      notify("Zip xong rồi, lưu về máy nhé!");
      saveAs(content, "hau.tools_output.zip");
    });
  }

  function saveLocal() {
    localStorage.setItem('raw', rawData);
    localStorage.setItem('context', context);
    localStorage.setItem('template', template);
    setIsUnsaved(false);
  }

  function generateContext(type) {
    let separateLines = rawData.split(/\r?\n|\r|\n/g);
    let result = "";

    let arrayData = "[\n";
    for (let i = 1; i < separateLines.length; i++) {
      let datanode = "\t{\n";

      for (let j = 0; j < separateLines[0].split(/\t/g).length; j++) {
        datanode += "\t\t\"" + separateLines[0].split(/\t/g)[j].replaceAll("'", "") + "\": ";
        datanode += "\"" + separateLines[i].split(/\t/g)[j] + "\"";
        if (j < separateLines[0].split(/\t/g).length - 1) datanode += ",";
        datanode += "\n"
      }
      datanode += "\t}";

      if (type === GENERATE_TYPE_ARRAY || type === GENERATE_TYPE_DATA) {
        if (i < separateLines.length - 1) datanode += ",";
        datanode += "\n"
        arrayData += datanode;
      } else if (type === GENERATE_TYPE_OBJECT) {
        setContext(datanode);
        return;
      }
    }
    arrayData += "]";

    if (type === GENERATE_TYPE_DATA) {
      result = "{\n";
      result += "\t\"data\": ";

      let lines = arrayData.split("\n");
      result += lines.map((line, index) => index === 0 ? line : "\t" + line).join("\n");
      result += "\n}";
    } else {
      result = arrayData;
    }

    setContext(result);
  }

  function generateQuery() {
    let separateLines = rawData.split(/\r?\n|\r|\n/g);
    let result = "";
    for (let i = 1; i < separateLines.length; i++) {
      let value = "";
      result += "INSERT INTO table_name (";
      for (let j = 0; j < separateLines[0].split(/\t/g).length; j++) {
        result += separateLines[0].split(/\t/g)[j].replaceAll("'", "");

        if (separateLines[0].split(/\t/g)[j].includes("'")) {
          value += "'" + separateLines[i].split(/\t/g)[j] + "'";
        } else {
          value += separateLines[i].split(/\t/g)[j];
        }


        if (j < separateLines[0].split(/\t/g).length - 1) {
          result += ",";
          value += ",";
        }
      }
      result += ") VALUES (" + value + ");";
      result += "\n"
    }
    setOutput(result);
    setLanguage("mysql");
  }

  function removeDuplicate(){
   const dataStr = rawData
    .split("\n")
    .filter((item, i, allItems) => {
      return i === allItems.indexOf(item);
    })
    .join("\n");
    setContext(dataStr);
  }


  function setData(key, val) {
    switch (key) {
      case "raw":
        setRawData(val);
        setIsUnsaved(true);
        break;
      case "context":
        setContext(val);
        setIsUnsaved(true);
        break;
      case "template":
        setTemplate(val);
        setIsUnsaved(true);
        break;
      case "ouput":
        setOutput(val);
        break;
      default:
    }
  }

  useEffect(() => {
    if (localStorage.getItem('raw') !== null) {
      setRawData(localStorage.getItem('raw'));
    }
    if (localStorage.getItem('context') !== null) {
      setContext(localStorage.getItem('context'));
      setTemplate(localStorage.getItem('template'));
    }
  }, []);


  function onSelect(opt) {
    localStorage.setItem('language', opt.value);
    setLanguage(opt.value);
  }

  function onSelectTemplate(opt) {
    getTemplate(opt.value);
  }
  const options = [
    "bat", "c", "cpp", "csharp", "css", "dart", "dockerfile", "go", "graphql", "html", "ini", "java", "javascript", "json", "kotlin", "less", "lua", "markdown", "mysql", "objective-c", "pascal", "perl", "pgsql", "php", "plaintext", "powershell", "proto", "pug", "python", "razor", "ruby", "rust", "scss", "shell", "sql", "swift", "twig", "typescript", "vb", "xml", "yaml"
  ];
  // "abap", "aes", "apex", "azcli", "bat", "bicep", "c", "cameligo", "clojure", "coffeescript", "cpp", "csharp", "csp", "css", "cypher", "dart", "dockerfile", "ecl", "elixir", "flow9", "fsharp", "go", "graphql", "handlebars", "hcl", "html", "ini", "java", "javascript", "json", "julia", "kotlin", "less", "lexon", "liquid", "lua", "m3", "markdown", "mips", "msdax", "mysql", "objective-c", "pascal", "pascaligo", "perl", "pgsql", "php", "pla", "plaintext", "postiats", "powerquery", "powershell", "proto", "pug", "python", "qsharp", "r", "razor", "redis", "redshift", "restructuredtext", "ruby", "rust", "sb", "scala", "scheme", "scss", "shell", "sol", "sparql", "sql", "st", "swift", "tcl", "twig", "typescript", "vb", "verilog", "xml", "yaml"
  const templateList = [
    "single", "multiple", "to_table"
  ];
  useEffect(() => {
    if (localStorage.getItem('language') !== null) {
      setLanguage(localStorage.getItem('language'));
    }
  }, []);


  const hideClass = (hide.filter(x => x === true).length === 1 ? "hideone" : hide.filter(x => x === true).length === 2 ? "hidetwo" : "");
  const outputReady = lsJson && lsJson.length > 0;
  useKey('ctrls', () => saveLocal());

  return (
    <div className="app">
      <ToastContainer />

      <div className="magicbar">
        <div className="toolbar">
          <div className="tool">
            <button onClick={() => setHide([!hide[0], hide[1], hide[2]])}> {!hide[0] ? "Hide" : "Display"} Raw</button>
            <button onClick={() => setHide([hide[0], hide[1], !hide[2]])}>{!hide[2] ? "Hide" : "Display"} Template</button>
          </div>
          <div className="tool">
            <button onClick={saveLocal} disabled={!isUnsaved}><VscSave /></button>
          </div>
          <div className="tool">
            <div className="logo">gen.haudeptrai.com</div>
            <div className="language"> Data: <Dropdown options={templateList} onChange={onSelectTemplate} placeholder="Init data" width={300} />
              Language:  <Dropdown options={options} onChange={onSelect} value={language} placeholder="Language" width={300} /></div>
          </div>
          <div className="tool">
            <button onClick={exportResult} disabled={!outputReady}><VscExport /> Export</button>
          </div>
        </div>
        <div className={"actionbar " + hideClass}>
          <div className={"column" + (hide[0] ? " hidden" : "")} >
            Data Tool:
            <button onClick={() => generateContext(GENERATE_TYPE_OBJECT)} title="Generate raw data to json object"> <VscJson /></button>
            <button onClick={() => generateContext(GENERATE_TYPE_ARRAY)} title="Generate raw data to json array"> <VscSymbolArray /></button>
            <button onClick={() => generateContext(GENERATE_TYPE_DATA)} title="Generate raw data to json array of object"> <VscBracketDot /></button>
            <button onClick={generateQuery} title="Generate raw data to SQL Query"> <VscDatabase /></button>
            <button onClick={removeDuplicate} title="Remove duplicate"> <VscChecklist /></button>
          </div>
          <div className={"column" + (hide[1] ? " hidden" : "")} >
            <button onClick={render}><VscListOrdered /></button> List (Multiple File)
            <i className="space" />
            <button onClick={renderSingle}> <VscNote /></button> Single
          </div>
          <div className={"column" + (hide[2] ? " hidden" : "")} >
            <button onClick={() => { window.open(`https://mozilla.github.io/nunjucks/templating.html`, '_blank') }}> Learn about templates </button>
          </div>
        </div>
      </div>
      <div className="workingarea">
        <div className={"input " + hideClass}>
          <CodeInput title="Raw Data"
            keymap="raw"
            hide={hide[0]}
            language="plaintext"
            data={rawData}
            updateValue={setData}
          />
          <CodeInput title="Context"
            keymap="context"
            hide={hide[1]}
            language="json"
            data={context}
            updateValue={setData}
          />
          <CodeInput title="Template"
            keymap="template"
            language="twig"
            hide={hide[2]}
            data={template}
            updateValue={setData}
          />
        </div>
        <div className="output">
          <div className="control">
            <div className="items">
              {lsJson?.map((item, id) => <div onClick={handleSetIndex} key={id} id={id} className={"item" + (parseInt(currentIdx) === parseInt(id) ? " active" : "")}> {item.name}</div>)}
              {lsJson && lsJson.length > 0 ? <></> : <>Chẳng có gì ở đây cả!<br />
                <center><VscHeartFilled size={128} color="#FCC8D1" /></center>
              </>}
            </div>
          </div>
          <div className="data">
            <CodeInput title=""
              keymap="output"
              language={language}
              hide={false}
              data={output}
              updateValue={setData}
            />
          </div>
        </div >
      </div>
    </div >
  );
}

export default App;
