export const context = `[
    {
        "name": "sample1",
        "value": "value1"
    },
    {
        "name": "sample2",
        "value": "value2"
    }
]`;

export const template = `---------------
{{name}}: {{value}}
`;
