import { template as s_template, context as s_context } from "./single";
import { template as m_template, context as m_context } from "./multiple";
import { template as t_template, context as t_context } from "./to_table";

export const contextData = (name) => {
    switch (name) {
        case "single":
            return s_context;
        case "multiple":
            return m_context;
        case "to_table":
            return t_context;
        default:
            return "";
    }
}

export const templateData = (name) => {
    switch (name) {
        case "single":
            return s_template;
        case "multiple":
            return m_template;
        case "to_table":
            return t_template;
        default:
            return "";
    }
}
